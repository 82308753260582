<template>
  <div id="chart">
    <apexchart ref = "chart1" type="line" width="100%" height="600px" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>

import axios from 'axios'
export default {
  components: {
  },

  data () {
    return {
      series: [{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Traffic Sources'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        xaxis: {
          type: 'datetime'
        },
        /* yaxis: [{
          title: {
            text: 'Website B6log',
          },
        }, {
          opposite: true,
          title: {
            text: 'Social Media'
          }
        }] */
      },
    }
  },

  computed: {
  },

  watch: {
  },

  created () {
  },

  mounted () {
  },

  methods: {
  },
}
</script>